
import Vue from 'vue'

export default Vue.extend({
  name: 'RPHAboutBlock',
  components: {
    RPHTextCollapsible: () => import('rph/molecules/RPHTextCollapsible/index.vue'),
    // RPHNotificationForm: () => import('rph/organisms/RPHNotificationForm/index.vue'),
  },
  props: {
    seoText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
})
