
import Vue from 'vue'

export default Vue.extend({
  name: 'RPHArticlesBlock',
  components: {
    RPHArticlesGrid: () => import('rph/organisms/RPHArticlesGrid/index.vue'),
    Heading: () => import('~/components/atoms/Heading.vue'),
  },
  props: {},
  data() {
    return {}
  },
  computed: {
    isTitle(): string {
      return this.$route.path === '/' ? 'Полезно знать' : 'Другие статьи про здоровье'
    },
  },
  methods: {},
})
