
import Vue, { PropType } from 'vue'
import RPHButton from 'rph/atoms/RPHButton/index.vue'

import { IBanner } from 'types/DTO/banners/index'

/* Блок рекламы */

export default Vue.extend({
  name: 'RPHAd',
  components: { RPHButton },
  props: {
    type: {
      type: String,
      default: '',
    },
    /* Будет ли блок прилипать */
    isStick: {
      type: Boolean,
      default: false,
    },
    banner: {
      type: Object as PropType<IBanner>,
    },
  },
  computed: {
    currentLink(): string {
      if (this.banner.link.link.includes('http' || 'https')) return 'a'
      return 'NuxtLink'
    },

    bannerEventParams(): { promotion_id: number; promotion_name: string } {
      return { promotion_id: this.banner.id, promotion_name: this.banner.title }
    },
  },

  async mounted() {
    const viewed = this.$services.banners.bannerViewChecked(
      document.querySelector('.rph-ad__image'),
    )
    if (viewed) {
      // @ts-ignore: Unreachable code error
      await this.$gtm.push({ event: 'view_promotion', ...this.bannerEventParams })
    }
  },
}) as any
