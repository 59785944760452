
import Vue, { PropType } from 'vue'
import { IArticle } from 'types/DTO/articles'
import { INewsCategory } from 'types/DTO/news'

export default Vue.extend({
  name: 'RPHArticleCard',
  components: {
    Heading: () => import('~/components/atoms/Heading.vue'),
  },
  props: {
    articleData: {
      type: Object as PropType<IArticle>,
      required: true,
    },
    isFull: {
      type: Boolean,
      default: false,
    },
    categoriesData: {
      type: Array as PropType<INewsCategory[]>,
      required: true,
    },
  },
  computed: {
    activeCategory(): any {
      return (
        this.categoriesData.find((item) => item.id === this.articleData.category.id) || {
          color: '#000',
          bg_color: '#FFF',
        }
      )
    },
  },
  methods: {},
})
