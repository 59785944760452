
import Vue from 'vue'
import { INewsCategory } from 'types/DTO/news'

export default Vue.extend({
  name: 'RPHArticlesGrid',
  components: {
    RPHArticleCard: () => import('rph/molecules/RPHArticleCard/index.vue'),
  },
  computed: {
    articles(): any {
      const mainPageElementAmount = 8
      const pageElementAmount = 4
      const articlesList = this.$store.getters['rph-news/articles']
      return this.$route.path === '/'
        ? articlesList.slice(0, mainPageElementAmount)
        : articlesList.slice(0, pageElementAmount)
    },
    categories(): INewsCategory[] {
      return this.$store.getters['rph-news/categories']
    },
  },
})
