
import Vue from 'vue'

export default Vue.extend({
  name: 'RPHTextCollapsible',
  components: {
    Arrow: () => import('icons/arrow-about.svg?inline'),
    RPHButton: () => import('rph/atoms/RPHButton/index.vue'),
    Heading: () => import('~/components/atoms/Heading.vue'),
  },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
      collapseElement: '' as any,
    }
  },
  computed: {
    isCollapsible(): boolean {
      return this.collapseElement
        ? this.collapseElement.scrollHeight > this.collapseElement.clientHeight + 20
        : false
    },
  },
  mounted() {
    const collapseElement = document.querySelector('.rph-text-collapsible__content')
    this.collapseElement = collapseElement
  },
  methods: {
    clickHandler(): void {
      this.isOpen = !this.isOpen
    },
  },
})
